* {
	padding: 0%;
	margin: 0%;
	box-sizing: border-box;
}

/** 
  Breakpoints:
  ------------
  sm: min-width: 640px; //small device
  md: min-width: 768px; // medium device
  lg: min-width: 1024px; // large device
  xl: min-width: 1280px; // extra large device
  2xl: min-width: 1536px; // 2 x extra large device
**/
.container {
	background-blend-mode: darken;
	background-position: center;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 80vh;
	width: 95%;
	margin-inline: auto;

	background: rgba(25, 1, 1, 0.15);
	backdrop-filter: blur(0px);
	-webkit-backdrop-filter: blur(0px);
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, 0.18);
	width: 95%;
	margin: auto;
	margin-block: 5vh;
	padding-top: 12vh;

	img::hover {
		cursor: pointer !important;
	}
}

@media screen and (max-width: 600px) {
	.container {
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 50vh;
		margin-block: 2vh;
	}
	.carousel {
		height: 100vh;
		min-height: 50vh !important;
	}
	.slide_active {
		min-height: 80vh;
		flex: 1;
		height: 100%;
	}
}
.heading {
	font-size: 40px;
	font-weight: 600;
	text-align: center;
	color: white;
}

.carouselContainer {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.merqueImage {
	width: 70vh;
	min-width: 40vh; /* Adjust width as needed */
	height: 70vh;
	margin-right: 10px; /* Adjust spacing between images */
	border-radius: 5px;
	box-shadow: 1px white;
}

@media only screen and (max-width: 600px) {
	.merqueImage {
		width: 40vh;
		min-width: 40vh; /* Adjust width as needed */
		height: 40vh;
	}
}

.box {
	backdrop-filter: blur(3px) saturate(200%);
	-webkit-backdrop-filter: blur(3px) saturate(200%);
	background-color: rgba(255, 255, 255, 0.07);
	border-radius: 12px;
	border: 1px solid rgba(209, 213, 219, 0.3);
	transition: all 0.3s ease-in-out;
	animation: blink 1s infinite; /* Blink animation */
}
.box:hover {
	cursor: pointer;
	transform: scale(0.9);
}

/* carousel */

.carousel {
	width: 100%;
	flex: 1;
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: row;
	transition: transform 0.5s ease;
	justify-content: center;

	align-items: center;
}

.slides {
	display: flex;
}

.slide {
	/* min-width: 100%; */
	display: none;
}

.slide_active {
	opacity: 1;
	width: 100% !important;
	height: 100%;
	flex: 1;
	min-height: 80vh;
}

.prev,
.next {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	background-color: transparent;
	border: none;
	padding: 10px 20px;
	cursor: pointer;
	font-size: 50px;
	color: white;
}

.close {
	position: absolute;
	top: 5%;
	right: 0%;
	background-color: transparent;
	border: none;
	padding: 10px 10px;
	cursor: pointer;
	font-size: 30px;
	color: white;
}
@media screen and (max-width: 600px) {
	.close {
		top: 1%;
		right: 0%;
		padding: 1px 1px;
		cursor: pointer;
		font-size: 20px;
		color: white;
	}
	.prev,
	.next {
		padding: 1px 1px;
		cursor: pointer;
		font-size: 30px;
		color: white;
	}

	.prev {
		left: 1px;
	}

	.next {
		right: 1px;
	}
	.close {
		right: 1px;
	}
}
.close {
	right: 10px;
}
.prev {
	left: 10px;
}

.next {
	right: 10px;
}

.blinkBox {
	border: 2px solid red; /* Initial border color */
	animation: blink 1s infinite; /* Blink animation */
}
.blinkBox:hover {
	cursor: pointer;
}

/* blink border */
@keyframes blink {
	0% {
		border-color: red;
	}
	50% {
		border-color: rgb(172, 252, 23);
	}
	100% {
		border-color: rgb(73, 246, 223);
	}
}
