.message{
	color: white;
	font-size: 5vh;
}

@media screen and (max-width:600px) {
	.message{
		color: white;
		font-size: 3vh;
		padding-inline: 1vh;
	}
}