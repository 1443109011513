.container{
    width: 95%;

margin-inline: auto;

background: rgba( 25, 1, 1, 0.15 );
backdrop-filter: blur( 0px );
-webkit-backdrop-filter: blur( 0px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
padding-inline: 1vh;
padding-top: 12vh;
}

.header{
    font-size: 7vh;
    color: white;
}
@media screen and (max-width:600px) {
    .container{
        width: 95%;
padding-top: 9vh;
    
    margin-inline: auto;
    
    }

    .header{
        font-size: 5vh;
    }
    
}
