.container {
	background: rgba(25, 1, 1, 0.15);
	backdrop-filter: blur(0px);
	-webkit-backdrop-filter: blur(0px);
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, 0.18);
	width: 95%;

	margin-inline: auto;
	padding-top: 10vh;
}

@media screen and (max-width: 700px) {
	.container {
		padding-block: 8vh !important;
	}
}
.image {
	width: 100%;
	max-height: 70vh !important;
	margin-block: 5vh;
	border-radius: 5px;
}
.header {
	text-align: left;
}
