.editBtn {
	font-size: 1.5em;
	transition: all 0.3s ease-in-out;
}

.editBtn:hover {
	cursor: pointer;
	transform: scale(0.8);
}

.imageEditBtn {
	font-size: 2vh;
	top: 5.5vh;
	left: 25vh;
}

.body {
	height: 80%;
	height: 50vh;
}

.img {
	width: 100%;
	height: 40vh !important;
	flex: 1;
}

@media screen and (max-width: 600px) {
	.imageEditBtn {
		font-size: 1.5em;
	}
}
