 *{
  margin: 0;  
  box-sizing: border-box;
  padding: 0;
 }
.App {
  text-align: center;
  background: url('./images/Picture1.png');
  background-size: cover;
  object-position: initial;
  background-repeat: no-repeat;
  background-position: center;
  object-fit: cover !important;

  background-attachment: fixed;  
  background-clip: border-box;
  position: relative;


}

