/* carousel */
.container img:hover {
	cursor: pointer;
}

.slides {
	display: flex;
	transition: transform 0.5s ease;
}

.slide {
	/* min-width: 100%; */
	display: none;
}

.slide_active {
	opacity: 1;
}

.prev,
.next {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	background-color: rgba(255, 255, 255, 0.5);
	border: none;
	padding: 10px 20px;
	cursor: pointer;
}

.prev {
	left: 10px;
}

.next {
	right: 10px;
}
