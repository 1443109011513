.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  background-color: transparent;
  transition: background-color 0.3s ease;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 30px;
}

.scrolled {
  background-color: rgba(0, 0, 0, 0.9);
}

.logo {padding-block: 10px;

}

.logohead {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  justify-content: center;

}

.logohead h3{
  font-size: 30px;
  color: white;
  margin: 0;
  padding: 0;
}

.hightlight {
  color: #f00;
}

.subhead {
  font-size: 0.8em;
  margin-top: 5px;
  font-size: 20px;
  color: white;

}

.headers {
  float: left;
  /* margin-top: 10px; */
  color: white;
}

.head {
  display: inline-block;
  margin-right: 20px;
  font-size: 1.2em;
  cursor: pointer;
  transition: width 0.2s ease-in-out;
}

.nav {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  transition: transform 0.3s ease;
  transform: translateX(-100%);
}

.menuOpen {
  transform: translateX(0);
  padding-top: 50%;
  font-size: 2vh;
}

.navList {
  list-style: none;
  padding: 0;
  margin: 0 !important;
}

.navItem {
  margin-bottom: 20px;
}

.navLink {
  color: white;
  text-decoration: none;
  font-size: 1.5em;
}

.hamburgerMenu {
  display: none;
}

@media (max-width: 768px) {
  .headers {
    display: none;
  }

  .hamburgerMenu {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 20px;
    cursor: pointer;
  }

  .hamburgerLine {
    width: 100%;
    height: 2vh;
    background-color: white;
    margin: 5px 0;
    transition: transform 0.3s ease;
  }

  .menuOpen .hamburgerLine:nth-child(1) {
    transform: rotate(45deg) translateY(6px);
  }

  .menuOpen .hamburgerLine:nth-child(2) {
    opacity: 0;
  }

  .menuOpen .hamburgerLine:nth-child(3) {
    transform: rotate(-45deg) translateY(-6px);
  }

  .nav {
    display: block;
  }
}

.hamburgerMenu {
  position: relative;
  width: 30px;
  height: 24px;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.hamburgerLine {
  position: absolute;
  width: 100%;
  height: 5px;
  background-color: white;
  transition: all 0.3s ease;
}

.hamburgerLine:nth-child(1) {
  top: -100%;
}

.hamburgerLine:nth-child(2) {
  top: -50%;

}

.hamburgerLine:nth-child(3) {
  top: 0%;
}

.closeMenu {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  z-index: 1000;
  transition: transform 0.3s ease;
}

.closeMenu:hover {
  transform: rotate(90deg);
}

.closeLine {
  position: absolute;
  width: 100%;
  height: 0.25rem;
  background-color: #333;
  transition: transform 0.3s ease;
}

.closeLine:nth-child(1) {
  top: 0;
  transform-origin: 20% 50%;
}

.closeLine:nth-child(2) {
  top: 50%;
  transform-origin: 50% 50%;
}



/* under line animation */

.head:hover{
  border-bottom: 2px solid white;
  
  animation: growunderline 0.3s forwards;
  
}

.headActive{
  border-bottom: 2px solid white;
  
}

