

.scrollToTopBtn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;

}

.scrollToTopBtn:hover {
  background-color: #0056b3;
}